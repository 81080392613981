<template>
  <section>
    <b-card>
      <TablePagination
        v-model="items"
        :url="`/admin/users/vps_accounts/supplier`"
        default-filter-by="username"
        :per-page="10"
        :fields="fields"
      >
        <template v-slot:purchased_by="data">
          {{ data.items.user.email }}
        </template>

        <template v-slot:ip="data">
          <b-button
            v-clipboard:copy="data.items.ip"
            v-clipboard:success="onCopy"
            size="sm"
            variant="relief-primary"
          >
            {{ data.items.ip }}
          </b-button>
        </template>

        <template v-slot:username="data">
          <b-button
            v-clipboard:copy="data.items.username"
            v-clipboard:success="onCopy"
            size="sm"
            variant="relief-primary"
          >
            {{ data.items.username }}
          </b-button>
        </template>

        <template v-slot:password="data">
          <b-button
            v-clipboard:copy="data.items.password"
            v-clipboard:success="onCopy"
            size="sm"
            variant="relief-primary"
          >
            {{ data.items.password }}
          </b-button>
        </template>

        <template v-slot:contact="">
        <!-- <img
          class="rounded-lg"
          :src="`${$api_host}/storage/assets/whatsapp.png`"
          width="35px"
          @click.stop="openSupport(data.items)"
        > -->
        </template>

        <template v-slot:plan_name="data">
          {{ data.items.plan_name }}
        </template>

        <template v-slot:status="data">
          <b-button
            v-if="status(data.items)"
            size="sm"
            variant="relief-success"
          >
            {{ statusMessage(data.items) }}
          </b-button>
          <b-button
            v-else
            size="sm"
            variant="relief-danger"
          >
            {{ statusMessage(data.items) }}
          </b-button>
        </template>

        <template v-slot:created_at="data">
          {{ data.items.created_at | date }}
        </template>
      </TablePagination>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner
} from 'bootstrap-vue'
import moment from 'moment'
import TablePagination from '@/components/TablePagination/Index.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    TablePagination
  },
  filters: {
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss', { trim: false })
    }
  },
  data: () => ({
    loading: false,
    show: false,
    username: '',
    items: [],
    fields: [
      {
        key: 'purchased_by',
        label: 'comprador por'
      },
      {
        key: 'ip',
        label: 'ip',
        sortable: true
      },
      {
        key: 'username',
        label: 'Usuário',
        sortable: true
      },
      {
        key: 'password',
        label: 'senha',
        sortable: true
      },
      {
        key: 'plan_name',
        label: 'Tipo de conta'
      },
      {
        key: 'status',
        label: 'status'
      },
      {
        key: 'created_at',
        label: 'Data de Compra'
      }
    ]
  }),
  methods: {
    search () {
      this.loading = true
      this.show = false
      this.items = []

      setTimeout(() => {
        this.loading = false
        this.show = true
      }, 2500)
    },
    status (item) {
      const end = moment(item.created_at).add(item.guarantee, 'days')

      if (moment().isAfter(end)) {
        return false
      }

      return true
    },
    statusMessage (item) {
      const end = moment(item.created_at).add(item.guarantee, 'days')
      const days = parseInt(end.diff(moment(), 'days'))

      if (days < 0) {
        return `Expirado há ${Math.abs(days)} dia(s)`
      }

      if (days === 0) {
        return 'Expira hoje'
      }

      if (days > 0) {
        return `Expira em ${days} dia(s)`
      }
    }
  }
}
</script>
